import iconModuleKommunikation from '../images/icon_module-kommunikation.svg'
import iconModuleRapportering from '../images/icon_module-rapportering.svg'
import iconModuleFeedback from '../images/icon_module-feedback.svg'
import iconModuleTraeningOgUddannelse from '../images/icon_module-traening-og-uddannelse.svg'
import iconModuleSocial from '../images/icon_module-social.svg'
import iconModuleBegivenhederOgKurser from '../images/icon_module-begivenheder-og-kurser.svg'

const modules = [{
    icon: iconModuleKommunikation,
    title: 'Kommunikation',
    features: ['Nyheder', 'Statistik', 'Tjekspørgsmål', 'Håndbøger'],
  },
  {
    icon: iconModuleRapportering,
    title: 'Rapportering',
    features: [
      'Formularer',
      'Nærvedulykker',
      'Fejl, mangler m.m.',
      'Varebestilling',
      'Procesdokumentation',
    ],
  },
  {
    icon: iconModuleFeedback,
    title: 'Feedback',
    features: ['Spørgeskemaer', 'Forslag og idéer', 'Arbejdshumør'],
  },
  {
    icon: iconModuleTraeningOgUddannelse,
    title: 'Træning og uddannelse',
    features: ['Sikkerhed', 'Kompetenceudvikling', 'Certificering'],
  },
  {
    icon: iconModuleSocial,
    title: 'Social',
    features: [
      'Vidensdeling',
      'Segmenterede chatgrupper',
      'Understøtter sund viksomhedskultur og godt arbejdsmiljø',
    ],
  },
  {
    icon: iconModuleBegivenhederOgKurser,
    title: 'Begivenheder og kurser',
    features: [
      'Medarbejderfordele',
      'Obligatoriske kurser',
      'Firmaarrangementer',
      'Tilmeldinger og deltagerlister',
    ],
  },
]

export default modules;