import React from 'react'
import { css } from '@emotion/core'
import colors from '../styles/colors'

const Spinner = props => (
  <div css={styles}>
    <div className="spinner" />
  </div>
)

function styles() {
  return css`
    display: inline-block;
    margin: 0.5rem;
    margin-bottom: calc(0.5rem + 4px);
    vertical-align: middle;

    .spinner {
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      border: 3px solid ${colors.seaGreen};
      border-color: ${colors.seaGreen} ${colors.seaGreen} ${colors.seaGreen}
        transparent;
      animation: spin infinite linear 0.8s;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `
}

export default Spinner
