import iconFeature3partsIntegration from "../images/icon_feature-3parts-integration.svg"
import iconFeaturePersonligProfil from "../images/icon_feature-personlig-profil.svg"
import iconFeaturePoint from "../images/icon_feature-point.svg"
import iconFeatureSegmentering from "../images/icon_feature-segmentering.svg"
import iconFeatureSmsService from "../images/icon_feature-sms-service.svg"
import iconFeatureSprogversionering from "../images/icon_feature-sprogversionering.svg"
import iconFeatureStatistik from "../images/icon_feature-statistik.svg"
import iconFeatureTelefonbog from "../images/icon_feature-telefonbog.svg"
import iconFeatureTilfoejDirekteIAppen from "../images/icon_feature-tilfoej-direkte-i-appen.svg"

const features = [{
    // link
    icon: iconFeature3partsIntegration,
    title: '3. parts integration',
    description: "Integration til eksterne systemer",
  },
  {
    // web
    icon: iconFeatureSprogversionering,
    title: 'Sprogversionering',
    description: "Brug samme app på flere sprog",
  },
  {
    // account-search-outline
    icon: iconFeatureSegmentering,
    title: 'Segmentering',
    description: "Målret indhold efter brugere, afdelinger, lokationer m.m.",
  },
  {
    // chart-line
    icon: iconFeatureStatistik,
    title: 'Statistik',
    description: "Se hvornår indhold bliver læst og af hvem",
  },
  {
    // cellphone-message
    icon: iconFeatureSmsService,
    title: 'SMS-service',
    description: "Send SMS’er til jeres medarbejdere",
  },
  {
    // cellphone-text
    icon: iconFeatureTilfoejDirekteIAppen,
    title: 'Tilføj indhold direkte i appen',
    description: "Indhold kan tilføjes direkte i appen",
  },
  {
    // account-card-details
    icon: iconFeatureTelefonbog,
    title: 'Telefonbog',
    description: "Nem kontakt til hele virksomheden",
  },
  {
    // account-circle
    icon: iconFeaturePersonligProfil,
    title: 'Personlig profil',
    description: "Alle brugere får en personlig profil med: profilbillede, kontaktinformation og indtillinger",
  },
  {
    // star-outline
    icon: iconFeaturePoint,
    title: 'Point',
    description: "Highscore. Skaber mere brugerengagement gennem gamification",
  },

]

export default features;