import React from 'react'

// Components
import Button from './button'
import Spinner from './spinner'

// Style
// import { css } from 'emotion'
import { css } from '@emotion/core'
import colors from '../styles/colors'
import common from '../styles/common'

import { DecagramCheckIcon } from 'mdi-react'
import { AlertDecagramIcon } from 'mdi-react'

class ContactForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      phone: '',
      message: '',

      sendingMessage: false,
      messageSent: false,
      messageFailedToSend: false,

      validationError: false,
      validationErrorMessage: '',
    }
  }

  updateEmail = event => {
    this.setState({ email: event.target.value })
  }

  updatePhone = event => {
    this.setState({ phone: event.target.value })
  }

  updateMessage = event => {
    this.setState({ message: event.target.value })
  }

  submitForm = event => {
    event.preventDefault()
    let formValidationResult = this.validateForm(
      this.state.email,
      this.state.message
    )
    if (formValidationResult.error) {
      this.setState({
        validationError: true,
        validationErrorMessage: formValidationResult.message,
      })
      return
    }

    this.setState({ sendingMessage: true })
    let request = new XMLHttpRequest()
    request.addEventListener('load', this.handleResponse)
    request.open(
      'GET',
      `/mail-service.asp?type=message&email=${this.state.email}&phone=${
        this.state.phone
      }&message=${this.state.message}`
    )
    request.send()
  }

  handleResponse = e => {
    if (
      e.target.status === 200 &&
      e.target.responseText.toUpperCase() === 'OK'
    ) {
      this.setState({ sendingMessage: false, messageSent: true })
    } else {
      this.setState({ sendingMessage: false, messageFailedToSend: true })
    }
  }

  validateForm = (email, message) => {
    if (!email) {
      return { error: true, message: 'Du mangler at tilføje din email' }
    }
    if (!message) {
      return { error: true, message: 'Du mangler at tilføje en besked' }
    }
    if (message.length > 1000) {
      return {
        error: true,
        message: 'Din besked er for lang. Du kan tilføje op til 1000 tegn',
      }
    }
    return { error: false }
  }

  render() {
    if (this.state.messageSent && !this.state.messageFailedToSend) {
      return (
        <div css={componentStyle} className={'message-sent'}>
          <DecagramCheckIcon className="u_mb-md" />
          <h3 className="u_mb-sm">Din besked er afsendt</h3>
          <p className="lead">
            Tak for din besked. Vi har sendt en kopi til din mail og kontakter
            dig hurtigst muligt.
          </p>
        </div>
      )
    } else if (this.state.messageFailedToSend) {
      return (
        <div css={componentStyle} className={'message-sent'}>
          <AlertDecagramIcon className="u_mb-md" />
          <h3 className="u_mb-sm">Kunne ikke sende besked</h3>
          <p className="lead">
            Der er sket en fejl med afsendelsen af din besked. Skriv til os på{' '}
            <a href="mailto:kontakt@toecho.dk">kontakt@toecho.dk</a> i stedet.
            Vi beklager ulejligheden.
          </p>
        </div>
      )
    } else {
      return (
        <form onSubmit={this.submitForm}>
          <div css={componentStyle} className={'full-width container'}>
            {this.state.validationError && (
              <div className="error-message u_mb-sm">
                <AlertDecagramIcon className="u_mr-xs" />
                <p>{this.state.validationErrorMessage}</p>
              </div>
            )}

            <div className="col col-50 left">
              <label htmlFor="email">
                Email
                <input
                  id="email"
                  type="text"
                  placeholder="Indtast din email"
                  onChange={this.updateEmail}
                  value={this.state.email}
                />
              </label>
              <label htmlFor="phone">
                Telefon (valgfri)
                <input
                  id="phone"
                  type="text"
                  placeholder="Indtast dit telefonnummer"
                  onChange={this.updatePhone}
                  value={this.state.phone}
                />
              </label>
            </div>
            <div className="col col-50 right">
              <label htmlFor="message">
                Besked
                <textarea
                  id="message"
                  placeholder="indtast din besked her..."
                  onChange={this.updateMessage}
                  value={this.state.message}
                  maxLength="1000"
                />
              </label>
            </div>
            {this.state.sendingMessage ? (
              <p className="text-right sending-message">
                <Spinner />
                Sender besked..{' '}
              </p>
            ) : (
              <span>
                <Button
                  type="fill dark"
                  text="Send besked"
                  nativeType="submit"
                />
                <div className="clearfix" />
              </span>
            )}
          </div>
        </form>
      )
    }
  }
}

function componentStyle() {
  return css`
    margin: 40px 0 200px 0 !important;

    .col {
      vertical-align: top;

      &.left {
        padding-right: 1%;
      }
      &.right {
        padding-left: 1%;
      }

      @media screen and (max-width: ${common.breakpointMedium}px) {
        width: 100% !important;
        margin: 0;
        padding: 0;

        &.right,
        &.left {
          padding: 0;
        }
      }
    }

    input,
    textarea {
      margin: 0.5rem 0 1.25rem 0;

      &:focus {
        outline: 1px ${colors.seaBlue} solid;
      }
    }

    textarea {
      min-height: 118px;
      resize: vertical;
    }

    button {
      float: right;
    }

    .sending-message {
      padding-top: 7px; /* Prevents layout-jump since text is same height as button */
    }

    div.clearfix {
      clear: both;
      content: '';
    }

    &.message-sent {
      text-align: center;
      padding: 50px 0 50px 0;

      p {
        margin: 0 auto;
        max-width: 500px;
      }
      svg {
        width: 3rem;
        height: 3rem;
        fill: ${colors.seaGreen};
      }
    }

    a {
      text-decoration: none;
      border-bottom: 1px ${colors.seaGreen} dashed;
      color: ${colors.seaGreen};
    }

    .error-message {
      display: flex;

      svg,
      p {
        display: inline-block;
        color: ${colors.red};
        fill: ${colors.red};
      }
    }
  `
}

export default ContactForm
