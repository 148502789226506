// Libraries
import React from 'react'
import { css } from '@emotion/core'
import TinyAnimate from 'TinyAnimate'
import Color from 'color'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

// Content
import modules from '../content/modules'
import features from '../content/features'

// Styles
import colors from '../styles/colors'
import typography from '../styles/typography'
import common from '../styles/common'

// Components
import Button from '../components/button'
import ContactForm from '../components/contactForm'
import FreeDemoModal from '../components/freeDemoModal'

// Images
import heroBackground_800 from '../images/frontpage-hero-background-800.jpg'
import heroBackground_1200 from '../images/frontpage-hero-background-1200.jpg'
import heroBackground_1600 from '../images/frontpage-hero-background-1600.jpg'
import heroBackground_default from '../images/frontpage-hero-background-default.jpg'
import ekkoAppAndEkkoUpdater from '../images/ekko-app-og-ekko-updater.jpg'
import iconCardDanskVirksomhed from '../images/icon_card-dansk-virksomhed.svg'
import iconCardGDPR from '../images/icon_card-gdpr.svg'
import iconCardJeresApp from '../images/icon_card-jeres-app.svg'
import iconCardJeresData from '../images/icon_card-jeres-data.svg'
import logoWhite from '../images/ekko-app_company-logo_white.svg'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalActive: false,
    }
  }

  readMoreScroll = () => {
    let from = window.pageYOffset
    let to =
      document.querySelector('.introduction').offsetTop - common.headerHeight
    TinyAnimate.animate(from, to, 240, apply, 'easeInOutQuart')
    function apply(scrollY) {
      window.scrollTo(0, scrollY)
    }
  }

  render() {
    return (
      <Layout>
        <div css={componentStyles}>
          <Helmet>
            <title>Ekko App - Vi skaber arbejdsglæde</title>
          </Helmet>
          {/* Hero */}
          <div css={heroStyles}>
            <div className="content-container">
              {/* <h1 className="u_mb-sm">Ekko App</h1> */}
              <img className="logo-main" src={logoWhite} alt="Ekko App Logo" />
              <h3 className="u_mb-md">
                Vi skaber <span className="bold">arbejdsglæde</span>
              </h3>
              <h4 className="lead u_mb-md">
                <span className="bold">
                  Motivér, uddan, del viden og succeshistorier
                </span>{' '}
                med en App, hvor funktionalitet og en{' '}
                <span className="bold">intuitiv brugerflade</span> går{' '}
                <span className="bold">hånd i hånd</span>
              </h4>
              <div className="actions-container">
                <Button
                  type="light fill hero-action"
                  size="large"
                  text="Gratis demo"
                  onClick={() => {
                    this.setState({ modalActive: true })
                  }}
                />
                <Button
                  type="light outline hero-action"
                  size="large"
                  text="Læs mere"
                  onClick={this.readMoreScroll}
                />
              </div>
            </div>
            <div className="background-fill" />
            <div className="background-image-container">
              <div className="background-image" />
            </div>
          </div>
          {/* Introduction */}
          <div className="container introduction">
            <div className="col col-45">
              <img
                src={ekkoAppAndEkkoUpdater}
                alt="Screenshots fra Ekko App™ og Ekko Updater™"
              />
            </div>
            <div className="col col-55">
              <h1 className="u_mb-sm">Konceptet er simpelt</h1>
              <p className="lead u_mb-sm">
                Med Ekko App foregår medarbejder
                <span className="u_conditional-word-break xl" />
                kommunikationen i en app, der er lige ved hånden. Appens indhold
                og moduler er tilpasset jeres virksomheds specifikke behov og
                den er sammensat af features, der engagerer, motiver og uddanner
                medarbejderne.
              </p>
              <h3 className="u_mb-md">
                Med Ekko App er vigtige informationer og kollegaer altid inden
                for rækkevidde.
              </h3>
            </div>
          </div>
          {/* Modules */}
          <div
            className="container full-width text-center modules"
            id="moduler"
          >
            <div className="inner-container">
              <h1 className="u_mb-sm">Moduler</h1>
              <p className="lead u_mb-lg">
                Ekko App består af moduler, som I selv udvælger.
              </p>
              <div className="modules-container">
                {modules.map((module, moduleIndex) => (
                  <div className="module-card" key={moduleIndex}>
                    <img src={module.icon} alt={module.title} />
                    <h3> {module.title} </h3>
                    <ul>
                      {module.features.map((feature, featureIndex) => (
                        <li key={featureIndex}> {feature} </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Features */}
          <div
            className="container full-width text-center features"
            id="features"
          >
            <div className="inner-container">
              <h1 className="u_mb-sm">Features</h1>
              <p className="lead u_mb-lg">
                Features er de grundlæggende funktioner der gør appen ekstra god
                i hverdagen. Vi har mange års erfaring med intranetløsninger til
                virksomheder og ved hvad der gør et intranet engagerende og
                motiverende
              </p>
              <div className="modules-container">
                {features.map((feature, featureIndex) => (
                  <div className="module-card" key={featureIndex}>
                    <img src={feature.icon} alt={feature.title} />
                    <h3> {feature.title} </h3> <p> {feature.description} </p>
                  </div>
                ))}
              </div>
            </div>

            <div className="background-fill" />
          </div>
          {/* Feature-cards */}
          <div className="container feature-cards">
            <div className="inner-container">
              <div className="feature-card">
                <img src={iconCardJeresApp} alt="" /> <h4>Jeres app</h4>
                <p>
                  Ekko App er jeres app. Den får jeres navn, farver og identitet
                </p>
              </div>
              <div className="feature-card">
                <img src={iconCardDanskVirksomhed} alt="" />{' '}
                <h4> Dansk virksomhed </h4>
                <p>
                  Vi er en dansk virksomhed. Vi forstår jer - og jeres hverdag
                </p>
              </div>
              <div className="feature-card">
                <img src={iconCardGDPR} alt="" /> <h4> GDPR - compliant </h4>
                <p>Vi følger førende retningslinier for håndtering af data </p>
              </div>
              <div className="feature-card">
                <img src={iconCardJeresData} alt="" />{' '}
                <h4> Jeres data er jeres </h4>
                <p>
                  Vores forretningsmodel er at levere apps - ikke data. Jeres
                  data forbliver jeres og vi videregiver ikke noget til 3.
                  parter
                </p>
              </div>
            </div>
          </div>
          {/* Contact */}
          <div className="container">
            <h1 className="u_mb-sm"> Vil du vide mere?</h1>
            <p className="lead u_mb-lg">
              Hos os elsker vi at dele viden og blive klogere. Kontakt os for at
              prøve en gratis demo. Udfyld formularen nedenfor eller ring på 71
              74 72 10
            </p>
            <ContactForm />
          </div>
          <FreeDemoModal
            active={this.state.modalActive}
            deactivate={() => this.setState({ modalActive: false })}
          />
        </div>
      </Layout>
    )
  }
}

function componentStyles() {
  return css`
    /* Introduction */
    div.container.introduction {
      padding: 50px 0;

      img {
        width: 100%;
      }

      @media screen and (max-width: 1000px) and (min-width: ${common.breakpointMedium}px) {
        .u_conditional-word-break.xl:before {
          content: '-';
          display: inline-block;
        }
      }

      .col {
        padding: 0 1rem;
        vertical-align: middle;
      }

      @media screen and (max-width: ${common.breakpointMedium}px) {
        .col {
          width: 100%;
          display: block;
          text-align: center;
        }
        img {
          width: 70%;
          margin-bottom: 5%;
        }
      }

      @media screen and (max-width: ${common.breakpointSmall}px) {
        img {
          width: 100%;
          margin-bottom: 5%;
        }
      }
    }

    /* Modules */
    div.container.modules {
      background-color: ${colors.ultraLightGrey};
      border-top: 1px ${colors.midGrey} solid;
      padding: 100px 0 200px 0;
    }
    div.modules-container {
      display: flex;
      justify-content: space-between;
      @media screen and (min-width: ${common.breakpointMedium + 1}px) {
        flex-wrap: wrap;
      }
      @media screen and (max-width: ${common.breakpointMedium}px) {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        width: 108%;
        margin: 0 -4%;
      }

      div.module-card {
        @media screen and (min-width: 901px) {
          padding: 2rem;
          width: 32%;
        }
        @media screen and (max-width: 900px) and (min-width: ${common.breakpointMedium +
            1}px) {
          width: 49%;
          padding: 2rem;
        }
        @media screen and (max-width: ${common.breakpointMedium}px) {
          min-width: 250px;
          width: 80vw;
          max-width: 350px;
          margin: 0.5rem;
          padding: 1rem;
          margin-bottom: 2rem;

          &:first-child {
            margin-left: 2rem;
          }
        }

        white-space: initial;
        background-color: ${colors.white};
        border: 1px ${colors.midGrey} solid;
        margin-bottom: 1.85%;
        border-radius: 3px;
        box-shadow: ${common.boxShadow};

        h3 {
          font-weight: 400;
          font-size: 1.5rem;
          margin: 2rem 0 1.75rem 0;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }

        li {
          margin: 0 0 1rem 0;
        }

        p,
        li {
          color: ${colors.black};
        }

        img {
          max-height: 48px;
          max-height: 48px;
        }
      }
    }

    /* Features */
    div.container.features {
      padding: 100px 0 200px 0;
      position: relative;
      z-index: 0;
      h1,
      p {
        color: ${colors.white};
      }

      .background-fill {
        background: linear-gradient(
          30deg,
          ${colors.seaGreen},
          ${colors.deepSeaBlue}
        );
      }
    }

    /* Feature cards */
    div.container.feature-cards {
      width: 100%;
      max-width: 100%;
      background-color: ${colors.ultraLightGrey};
      margin: -170px 0 150px 0;
      padding: 100px 0 0 0;
      text-align: center;
      border-bottom: 1px ${colors.midGrey} solid;

      .inner-container {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;

        .feature-card {
          padding: 4.5rem 1rem 3rem 1rem;
          @media (min-width: ${common.breakpointLarge + 1}px) {
            flex: 1 1 20%; /* 20 instead of 25 for IE11 */
            border-right: 1px ${colors.midGrey} solid;
            &:nth-child(4) {
              border-right: 0px ${colors.midGrey} solid;
            }
          }

          @media (max-width: ${common.breakpointLarge}px) and (min-width: ${common.breakpointMedium +
              1}px) {
            flex: 1 1 45%; /* 45 instead of 50 for IE11 */
            &:nth-child(odd) {
              border-right: 1px ${colors.midGrey} solid;
            }
            &:nth-child(1) {
              border-bottom: 1px ${colors.midGrey} solid;
            }
            &:nth-child(2) {
              border-bottom: 1px ${colors.midGrey} solid;
            }
          }
          @media (max-width: ${common.breakpointMedium}px) {
            flex: 1 0 95%; /* 95 instead of 100 for IE11 */
            border-right: 0px transparent solid;
            border-bottom: 1px ${colors.midGrey} solid;
            &:nth-child(4) {
              border-bottom: 0px ${colors.midGrey} solid;
            }
          }
        }
      }

      img {
        max-height: 48px;
        margin: 0 auto;
        margin-bottom: 2rem;
      }

      h4 {
        color: ${colors.seaGreen};
        font-size: 1.25rem;
        margin-bottom: 1rem;
      }
    }
  `
}

function heroStyles() {
  return css`
    min-height: 550px;
    position: relative;
    color: ${colors.white};
    text-align: center;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    padding-top: 240px;
    padding-bottom: 320px;

    @media screen and (max-width: ${common.breakpointMedium}px) and (min-width: ${common.breakpointSmall +
        1}px) {
      padding-top: 180px;
      padding-bottom: 240px;
    }

    @media screen and (max-width: ${common.breakpointSmall}px) {
      padding-top: 100px;
      padding-bottom: 120px;
    }
    /* Content */
    div.content-container {
      position: relative;
      max-width: 600px;
      width: 90%;
      margin: 0 auto;
    }

    @media screen and (max-width: ${common.breakpointSmall}px) {
      .hero-action {
        width: 100%;
        display: block;
        margin: 0 0 1rem 0;
      }
    }

    /* Background gradient */
    div.background-fill {
      background: linear-gradient(
        30deg,
        ${Color(colors.seaGreen)
          .darken(0.7)
          .hex()},
        ${Color(colors.deepSeaBlue)
          .saturate(0.3)
          .hex()}
      );
    }

    div.background-image-container {
      z-index: -1;
      position: absolute;
      top: calc(-35px + -1vw);
      width: 100%;
      height: 100%;
      transform: skewY(-2.25deg);
      background-size: cover;
      background-position: center;
      opacity: 0.3;
      background-image: url(${heroBackground_default});

      @media screen and (max-width: 1600px) {
        background-image: url(${heroBackground_1600});
      }
      @media screen and (max-width: 1200px) {
        background-image: url(${heroBackground_1200});
      }
      @media screen and (max-width: 800px) {
        background-image: url(${heroBackground_800});
      }
    }

    /* Types */
    h1 {
      ${typography.mainHeading};
      font-weight: 400;
      font-size: 3.5rem;
      color: ${colors.white} !important;
    }
    .logo-main {
      width: 60%;
    }
    h3,
    h4 {
      ${typography.tertiaryHeading};
      font-weight: 300;
      color: ${colors.white} !important;
      span.bold {
        font-weight: 500;
      }
    }
    h3 {
      font-size: 1.75rem;
    }
    h4 {
      font-size: 1.5rem;
    }

    button {
      margin: 0 0.5rem;
    }
  `
}

export default IndexPage
